import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Custom global styles if any
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'; // To reset and normalize styles globally
import theme from './theme'; // Import your custom MUI theme
import { BrowserRouter as Router } from 'react-router-dom'; // For routing
import firebase from './firebase'; // Your Firebase configuration


/*
function App2() {
  const firestoreInstance = getFirestore(useFirebaseApp());
  return (
    <FirestoreProvider sdk={firestoreInstance}>
          <App />

    </FirestoreProvider>
  );
}*/

ReactDOM.render(
  <React.StrictMode>
    {/* Provide Firebase app context */}
      {/* Apply the MUI theme and CssBaseline */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* Wrap the entire app with Router for routing support */}
          <App />
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root') // This is the root div in your public/index.html
);
