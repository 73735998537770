import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { Box, Typography, Alert, DialogContent, Divider, Card, CardContent, Button, Dialog, DialogActions, DialogTitle, TextField, FormControl, InputLabel, MenuItem, Select, IconButton } from '@mui/material';
import Loading from '../../components/Loading';
import axios from 'axios';  // Axios for making API requests
import { getAuth } from "firebase/auth";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DeviceOverview = () => {
  const { id } = useParams();
  const auth = getAuth();
  const [device, setDevice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shareLink, setShareLink] = useState('');  // State to store the share link
  const [loadingShareLink, setLoadingShareLink] = useState(false); // Loading state for fetching the share link
  const [shareLinkError, setShareLinkError] = useState(null); // Error state for fetching share link
  const [openDialog, setOpenDialog] = useState(false);  // State for dialog box
  const [showSettings, setShowSettings] = useState(false);  // State to toggle additional settings

  // Share link settings
  const [permission, setPermission] = useState('viewer');
  const [expiryDate, setExpiryDate] = useState(null);  // State to store the expiry date
  const [useLimit, setUseLimit] = useState('');  // State to store use limit

  const Admin = /*device && device.users && (device.users[auth.currentUser.uid]) &&*/(device?.users[auth.currentUser.uid]?.permission) && (["admin","owner"].includes(device.users[auth.currentUser.uid].permission)) 


  useEffect(() => {
    const fetchDevice = async () => {
      setLoading(true);
      try {
        const deviceDoc = await db.collection('devices').doc(id).get();
        if (deviceDoc.exists) {
          const deviceData = { id: deviceDoc.id, ...deviceDoc.data() };
          setDevice(deviceData);
        } else {
          setError('Device not found');
        }
      } catch (error) {
        setError('Failed to load device data');
      } finally {
        setLoading(false);
      }
    };

    fetchDevice();
  }, [id]);

  // Function to fetch the share link from the API
  const getShareLink = async () => {
    setLoadingShareLink(true);
    setShareLinkError(null);

    try {
      const token = await auth.currentUser.getIdToken(true);  // Get the ID token
      const data = {
        deviceId: id,
      };

      // Add additional fields if settings are shown
      if (showSettings) {
        data.permission = permission;
        data.expiryDate = expiryDate || null;
        data.useLimit = useLimit || null;
      }

      const config = {
        method: 'post',
        data,
        url: 'https://dashboard.api.hidenbeep.com/share/link',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
      };

      const response = await axios(config);

      if (response.data.shareKey) {
        const generatedLink = `https://dashboard.hidenbeep.com/share/${id}/${response.data.shareKey}`; // Construct URL
        setShareLink(generatedLink);
      } else {
        setShareLinkError('Failed to generate share link');
      }
    } catch (error) {
      console.error('Error fetching share link:', error);
      setShareLinkError('Error fetching share link');
    } finally {
      setLoadingShareLink(false);
    }
  };

  const handleDialogOpen = () => {
    setShareLink(false)
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setShowSettings(false); // Reset the settings visibility when closing the dialog
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
  };

  const toggleSettings = () => {
    setShowSettings((prev) => !prev);  // Toggle the visibility of the additional settings
  };

  if (loading) return <Loading />;
  if (error) return <Alert severity="error">{error}</Alert>;

  // Function to format the last activity date
  const formatDate = (date) => {
    if (!date) return 'Unknown';
    const dateObj = new Date(date.seconds * 1000); // Assuming Firestore timestamp
    return dateObj.toLocaleString();
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>

      {/* Device Overview Section */}
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Device Overview: {device.name}
        </Typography>
        <Divider />
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h6">Device Details</Typography>
            <Typography>Name: {device.name}</Typography>
            <Typography>Status: {device.status || 'Unknown'}</Typography>
            <Typography>Type: {device.type || 'Unknown'}</Typography>
            <Typography>Last Activity: {formatDate(device.lastActivity)}</Typography>
          </CardContent>
        </Card>
      </Box>

      {/* Additional Information Section */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Additional Information
        </Typography>
        <Divider />
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="body1">
              {device.additionalInfo || 'No additional information available for this device.'}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      {/* Share Link Section with Settings Dialog */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Share Device Access
        </Typography>
        <Divider />

        {/* Hide the buttons if the share link has been generated */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogOpen}
            sx={{ mt: 2 }}
          >
            Share Link
          </Button>
        
      </Box>

      {/* Dialog for Settings and Share Link */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Share Link</DialogTitle>
        <DialogContent>
        {!shareLink && (
          <>
          {/* Button to toggle additional settings */}
          <Button
            variant="outlined"
            color="secondary"
            onClick={toggleSettings}
            sx={{ mb: 2 }}
          >
            {showSettings ? 'Hide Additional Settings' : 'Show Additional Settings'}
          </Button>

          {/* Additional Settings (Hidden by default) */}
          {showSettings && (
            <>
            {Admin && (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Permission</InputLabel>
                <Select
                  value={permission}
                  onChange={(e) => setPermission(e.target.value)}
                >
                  <MenuItem value="viewer">Viewer</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
              </FormControl>)}

              <TextField
                fullWidth
                label="Expiry Date (optional)"
                type="date"
                value={expiryDate || ''}
                onChange={(e) => setExpiryDate(e.target.value)}
                sx={{ mb: 2 }}
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                fullWidth
                label="Use Limit (optional)"
                type="number"
                value={useLimit}
                onChange={(e) => setUseLimit(e.target.value)}
                sx={{ mb: 2 }}
              />
            </>
          )}
          </>

        )}

          {/* Display the share link or error */}
          {shareLink && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <TextField
                fullWidth
                label="Share Link"
                value={shareLink}
                disabled
                sx={{ mr: 2 }}
              />
              <IconButton onClick={handleCopyLink}>
                <ContentCopyIcon />
              </IconButton>
            </Box>
          )}
          {shareLinkError && (
            <Alert severity="error" sx={{ mb: 2 }}>{shareLinkError}</Alert>
          )}
        </DialogContent>
        <DialogActions>

          
        {!shareLink && (

          <Button
            variant="contained"
            color="primary"
            onClick={getShareLink}
            disabled={loadingShareLink}
            sx={{ mb: 2 }}
          >
            {loadingShareLink ? 'Generating...' : 'Get Share Link'}
          </Button>
        )}

          <Button onClick={handleDialogClose} color="secondary">Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeviceOverview;
