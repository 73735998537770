import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { Box, Alert, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import Loading from '../../components/Loading';
import DeviceTabs from '../../components/DeviceTabs'; // Import DeviceTabs
import Page404 from '../Page404'; // Import the 404 page

const DeviceLogs = () => {
  const { id } = useParams();
  const [deviceLogs, setDeviceLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deviceFound, setDeviceFound] = useState(true); // Track if the device is found

  useEffect(() => {
    const fetchDeviceLogs = async () => {
      setLoading(true);
      try {
          const logsSnapshot = await db.collection('devices').doc(id).collection('log').get();
          const logs = logsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setDeviceLogs(logs);
        
      } catch (error) {
        setError('Failed to load device logs');
      } finally {
        setLoading(false);
      }
    };

    fetchDeviceLogs();
  }, [id]);



  if (loading) return <Loading />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ width: '100%' }}>


      {/* Log List */}
      {deviceLogs.length > 0 ? (
        <List>
          {deviceLogs.map(log => (
            <ListItem key={log.id}>
              <ListItemText
                primary={log.timestamp && `Log at ${new Date(log.timestamp.seconds * 1000).toLocaleString()}`}
                secondary={log.message}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Alert severity="info">No logs available for this device.</Alert>
      )}

    </Box>
  );
};

export default DeviceLogs;
