import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
import 'firebase/compat/storage'
import { initializeApp } from 'firebase/app';

//import  Firebaseui  from 'firebaseui'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6SZVoCPxScKz44uPMTaclg0qmESvr-Tk",
  authDomain: "hide-n-beep.firebaseapp.com",
  projectId: "hide-n-beep",
  storageBucket: "hide-n-beep.appspot.com",
  messagingSenderId: "914909939974",
  appId: "1:914909939974:web:7c636ee648b545cc3e7687",
  measurementId: "G-ZVWK1WHFPT"
};
// Initialize Firebase
//export const firebaseApp = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);

export default firebase;
export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();

/*
firebase.initializeApp(firebaseConfig);
export const analytics =  typeof window !== "undefined" ? firebase.analytics() :null;
//firebase.storage();

export default firebase;
//export const analytics =  getAnalytics(app);
export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();

/*
// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  connectAuthEmulator(auth2,'http://localhost:9099/');//, { disableWarnings: true });
}*/
