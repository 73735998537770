import React, { useState, useEffect } from 'react';
import {Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { Box, Typography, Alert, CircularProgress, Button } from '@mui/material';

const DeviceSharePage = () => {
  const { deviceId, deviceShareKey } = useParams();
  const navigate = useNavigate();
  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const connectDevice = async () => {
      setLoading(true);
      try {
        const token = await auth.currentUser.getIdToken(true);  // Get the ID token
        
        const response = await axios.post('https://dashboard.api.hidenbeep.com/share', {
          deviceId,
          deviceShareKey
        }, {
          headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        if (response.data.success) {
          setSuccessMessage('Device successfully connected to your account.');
        } else {
          setError('Failed to connect the device. Please check the share key or contact support.');
        }
      } catch (error) {
        console.error('Error connecting device:', error);
        setError('An error occurred while connecting the device. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    connectDevice();
  }, [deviceId, deviceShareKey]);

  const handleRedirect = () => {
    navigate(`/device/${deviceId}`);
  };

  if (loading) return <CircularProgress />;

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      {error && <Alert severity="error">{error}</Alert>}
      {successMessage && (
        <Box>
          <Alert severity="success">{successMessage}</Alert>
          <Button variant="contained" color="primary" component={Link} to={ `/device/${deviceId}`} sx={{ mt: 2 }}>
            Go to Device
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default DeviceSharePage;
