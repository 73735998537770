import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { Box,MenuItem,Select,FormControl,InputLabel, Divider,Alert, DialogContent, Button, List, ListItem, ListItemText } from '@mui/material';
import Loading from '../../components/Loading';
import DeviceTabs from '../../components/DeviceTabs'; // Import DeviceTabs
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const DeviceSchedule = () => {
  const { id } = useParams();
  const [device, setDevice] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [schedule, setSchedule] = useState({});

  
  // Default sound state
  const [defaultSound, setDefaultSound] = useState('random');
  const [randomSoundSettings, setRandomSoundSettings] = useState({ minFreq: '', maxFreq: '', minDuration: '', maxDuration: '' });
  const [fixedSoundSettings, setFixedSoundSettings] = useState({ freq: '', duration: '' });
  const [selectedSound, setSelectedSound] = useState(''); // Assuming this is fetched from a sound list

  // Event schedule state
  const [eventSchedule, setEventSchedule] = useState('schedule'); // 'schedule', 'random', 'periodic'
  const [cronSettings, setCronSettings] = useState({ time: '', day: '', month: '', year: '' });
  const [randomEventSettings, setRandomEventSettings] = useState({ minHours: '', maxHours: '' });
  const [periodicSettings, setPeriodicSettings] = useState({ interval: '', unit: 'minutes' }); // unit can be 'minutes', 'hours', or 'days'




  useEffect(() => {
    const fetchDeviceAndSchedules = async () => {
      setLoading(true);
      try {
        // Fetch the device document
        const deviceDoc = await db.collection('devices').doc(id).get();
        if (deviceDoc.exists) {
          setDevice({ id: deviceDoc.id, ...deviceDoc.data() });

          // Fetch schedules from the sub-collection "schedules"
          const schedulesSnapshot = await db.collection('devices').doc(id).collection('schedules').get();
          const fetchedSchedules = schedulesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setSchedules(fetchedSchedules);
        } else {
          setError('Device not found');
        }
      } catch (error) {
        setError('Failed to load device data');
      } finally {
        setLoading(false);
      }
    };

    fetchDeviceAndSchedules();
  }, [id]);

  
  const handleOpen = async (data={}) => {

    setSchedule(data)

    setDefaultSound(data.defaultSound || 'random');
    setRandomSoundSettings(data.randomSoundSettings || { minFreq: '', maxFreq: '', minDuration: '', maxDuration: '' });
    setFixedSoundSettings(data.fixedSoundSettings || { freq: '', duration: '' });
    setSelectedSound(data.selectedSound || '');

    setEventSchedule(data.eventSchedule || 'random');
    setCronSettings(data.cronSettings || { time: '', day: '', month: '', year: '' });
    setRandomEventSettings(data.randomEventSettings || { minHours: '', maxHours: '' });
    setPeriodicSettings(data.periodicSettings || { interval: '', unit: 'minutes' });

    setOpenDialog(true)

  }

  

  const handleAddSchedule = async () => {
    try {

      // Construct the updated device data
    const updatedDevice = {
      ...schedule,
      defaultSound,
      randomSoundSettings: defaultSound === 'random' ? randomSoundSettings : null,
      fixedSoundSettings: defaultSound === 'set' ? fixedSoundSettings : null,
      selectedSound: defaultSound === 'sound' ? selectedSound : null,
      eventSchedule,
      cronSettings: eventSchedule === 'schedule' ? cronSettings : null,
      randomEventSettings: eventSchedule === 'random' ? randomEventSettings : null,
      periodicSettings: eventSchedule === 'periodic' ? periodicSettings : null,
    };

      const newScheduleRef = await db.collection('devices').doc(id).collection('schedules').add(updatedDevice);
      setSchedules([...schedules, { id: newScheduleRef.id, ...updatedDevice }]);
      setOpenDialog(false);
      //setNewSchedule({ time: '', action: '' });
    } catch (error) {
      setError('Error adding schedule');
    }
  };

  const handleRemoveSchedule = async (scheduleId) => {
    try {
      await db.collection('devices').doc(id).collection('schedules').doc(scheduleId).delete();
      setSchedules(schedules.filter(schedule => schedule.id !== scheduleId));
    } catch (error) {
      setError('Error deleting schedule');
    }
  };

  if (loading) return <Loading />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ width: '100%' }}>

      <DialogContent>
        <Box sx={{ my: 4 }}>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            onClick={()=>handleOpen()}
            sx={{ mb: 2 }}
          >
            Add Schedule
          </Button>

          {/* List of schedules */}
          {schedules.length === 0 ? (
            <Alert severity="info">No schedules available for this device.</Alert>
          ) : (
            <List>
              {schedules.map((schedule) => (
                <ListItem key={schedule.id}>
                  <ListItemText
                    primary={`name: ${schedule.name}`}
                  />
                  <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveSchedule(schedule.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </DialogContent>

      {/* Add Schedule Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <TextField
            label="Name"
            type="text"
            fullWidth
            value={schedule.name}
            onChange={(e) => setSchedule({ ...schedule, name: e.target.value })}
            sx={{ mb: 2 }}
        />
          




          
        <Divider />

{/* Default Sounds */}
<FormControl fullWidth sx={{ mb: 2 }}>
  <InputLabel>Default Sound</InputLabel>
  <Select
    value={defaultSound}
    onChange={(e) => setDefaultSound(e.target.value)}
    fullWidth
  >
    <MenuItem value="random">Random</MenuItem>
    <MenuItem value="sound">Select Sound</MenuItem>
    <MenuItem value="set">Set Frequency and Duration</MenuItem>
  </Select>
</FormControl>

{/* Random Sound Settings */}
{defaultSound === 'random' && (
  <Box sx={{ mb: 2 }}>
    <TextField
      label="Min Frequency"
      type="number"
      min={0}
      max={20000}
      InputProps={{ inputProps: { min: 0, max: 20000 } }}
      value={randomSoundSettings.minFreq}
      onChange={(e) => setRandomSoundSettings({ ...randomSoundSettings, minFreq: e.target.value })}
      sx={{ mr: 2 }}
    />
    <TextField
      label="Max Frequency"
      type="number"
      min={0}
      max={20000}
      InputProps={{ inputProps: { min: 0, max: 20000 } }}
      value={randomSoundSettings.maxFreq}
      onChange={(e) => setRandomSoundSettings({ ...randomSoundSettings, maxFreq: e.target.value })}
      sx={{ mr: 2 }}
    />
    <TextField
      label="Min Duration"
      type="number"
      min={0}
      max={60}
      InputProps={{ inputProps: { min: 0, max: 60 } }}
      value={randomSoundSettings.minDuration}
      onChange={(e) => setRandomSoundSettings({ ...randomSoundSettings, minDuration: e.target.value })}
      sx={{ mr: 2 }}
    />
    <TextField
      label="Max Duration"
      type="number"
      min={0}
      max={60}
      InputProps={{ inputProps: { min: 0, max: 60 } }}
      value={randomSoundSettings.maxDuration}
      onChange={(e) => setRandomSoundSettings({ ...randomSoundSettings, maxDuration: e.target.value })}
    />
  </Box>
)}

{/* Select Sound */}
{defaultSound === 'sound' && (
  <FormControl fullWidth sx={{ mb: 2 }}>
    <InputLabel>Select a Sound</InputLabel>
    <Select
      value={selectedSound}
      onChange={(e) => setSelectedSound(e.target.value)}
    >
      <MenuItem value="sound1">Sound 1</MenuItem>
      <MenuItem value="sound2">Sound 2</MenuItem>
      <MenuItem value="sound3">Sound 3</MenuItem>
    </Select>
  </FormControl>
)}

{/* Set Frequency and Duration */}
{defaultSound === 'set' && (
  <Box sx={{ mb: 2 }}>
    <TextField
      label="Frequency"
      type="number"
      value={fixedSoundSettings.freq}
      onChange={(e) => setFixedSoundSettings({ ...fixedSoundSettings, freq: e.target.value })}
      sx={{ mr: 2 }}
    />
    <TextField
      label="Duration"
      type="number"
      value={fixedSoundSettings.duration}
      onChange={(e) => setFixedSoundSettings({ ...fixedSoundSettings, duration: e.target.value })}
    />
  </Box>
)}

{defaultSound !== 'none' && (
  <>
    <Divider />
    {/* Event Schedule */}
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel>Event Schedule</InputLabel>
      <Select
        value={eventSchedule}
        onChange={(e) => setEventSchedule(e.target.value)}
        fullWidth
      >
      <MenuItem value="random">Random Interval</MenuItem>
        <MenuItem value="schedule">Schedule</MenuItem>
        <MenuItem value="periodic">Periodic</MenuItem>
      </Select>
    </FormControl>

    {/* Schedule Settings */}
    {eventSchedule === 'schedule' && (
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Time"
          type="time"
          value={cronSettings.time}
          onChange={(e) => setCronSettings({ ...cronSettings, time: e.target.value })}
          sx={{ mr: 2 }}
        />
        <TextField
          label="Day of Month"
          type="number"
          value={cronSettings.day}
          onChange={(e) => setCronSettings({ ...cronSettings, day: e.target.value })}
          sx={{ mr: 2 }}
        />
        <TextField
          label="Month"
          type="number"
          value={cronSettings.month}
          onChange={(e) => setCronSettings({ ...cronSettings, month: e.target.value })}
          sx={{ mr: 2 }}
        />
        <TextField
          label="Year"
          type="number"
          value={cronSettings.year}
          onChange={(e) => setCronSettings({ ...cronSettings, year: e.target.value })}
        />
      </Box>
    )}

    {/* Random Event Settings */}
    {eventSchedule === 'random' && (
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Min Hours"
          type="number"
          value={randomEventSettings.minHours}
          onChange={(e) => setRandomEventSettings({ ...randomEventSettings, minHours: e.target.value })}
          sx={{ mr: 2 }}
        />
        <TextField
          label="Max Hours"
          type="number"
          value={randomEventSettings.maxHours}
          onChange={(e) => setRandomEventSettings({ ...randomEventSettings, maxHours: e.target.value })}
        />
      </Box>
    )}

    {/* Periodic Event Settings */}
    {eventSchedule === 'periodic' && (
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Interval"
          type="number"
          value={periodicSettings.interval}
          onChange={(e) => setPeriodicSettings({ ...periodicSettings, interval: e.target.value })}
          sx={{ mr: 2 }}
        />
        <FormControl>
          <InputLabel>Unit</InputLabel>
          <Select
            value={periodicSettings.unit}
            onChange={(e) => setPeriodicSettings({ ...periodicSettings, unit: e.target.value })}
          >
            <MenuItem value="minutes">Minutes</MenuItem>
            <MenuItem value="hours">Hours</MenuItem>
            <MenuItem value="days">Days</MenuItem>
          </Select>
        </FormControl>
      </Box>
    )}
  </>
)}



          <Button variant="contained" color="primary" onClick={handleAddSchedule}>
            Add Schedule
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DeviceSchedule;
