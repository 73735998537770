import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import {
  List, ListItem, Toolbar, ListItemText, ListItemSecondaryAction, Dialog, DialogTitle, DialogContent,
  DialogActions, TextField, IconButton, CircularProgress, Alert, Tabs, Tab, FormControl,
  InputLabel, Select, MenuItem, Box, Button, DialogContentText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Loading from '../components/Loading';
import { getAuth } from "firebase/auth";

const SoundsPage = () => {
  const auth =  getAuth();
  const [sounds, setSounds] = useState([]);
  const [defaultSounds, setDefaultSounds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingSound, setEditingSound] = useState(null);
  const [newSound, setNewSound] = useState({ name: '', sequence: [], tempo: 120, tabValue: 0 });
  const [tabValue, setTabValue] = useState(1);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [soundToDelete, setSoundToDelete] = useState(null);
  const [importDialogOpen, setImportDialogOpen] = useState(false);

  // Fetch sounds from db
  useEffect(() => {
    const fetchSounds = async () => {
      setLoading(true);
      setError(null);
      try {
        const currentUser = auth.currentUser;

        const soundsCollection = await db.collection('sounds').where(`users.${currentUser.uid}`, '!=', null) // Filter devices based on current user
        .get();
        setSounds(soundsCollection.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        setError('Failed to load sounds. Please try again later.');
        console.error('Error fetching sounds:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSounds();
  }, [auth]);

  // Fetch default sounds from sounds-default collection
  const fetchDefaultSounds = async () => {
    try {
      setLoading(true);
      const defaultSoundsCollection = await db.collection('sounds-default').get();
      setDefaultSounds(defaultSoundsCollection.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      setError('Error loading default sounds.');
      console.error('Error fetching default sounds:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotalDuration = (sequence) => {
    return sequence.reduce((total, noteObj) => total + (parseFloat(noteObj.duration) || 0), 0).toFixed(2);
  };

  const handleDialogOpen = (sound = null) => {
    if (sound) {
      setEditingSound(sound);
      setNewSound({
        name: sound.name,
        sequence: sound.sequence || [],
        tempo: sound.tempo || 120,
        tabValue: sound.tabValue || 0,
      });
      setTabValue(sound.tabValue || 1);
    } else {
      setEditingSound(null);
      setNewSound({ name: '', sequence: [], tempo: 120, tabValue: 0 });
      setTabValue(1);
    }
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSaveSound = async () => {
    const currentUser = auth.currentUser;

    const soundToSave = { ...newSound, tabValue };
    try {
      if (editingSound) {
        await db.collection('sounds').doc(editingSound.id).update(soundToSave);
      } else {
        await db.collection('sounds').add({...soundToSave,"users":{[currentUser.uid]:"admin"}});
      }
      const soundsCollection = await db.collection('sounds').get();
      setSounds(soundsCollection.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      setOpenDialog(false);
    } catch (error) {
      setError('Error saving sound. Please try again.');
      console.error('Error saving sound:', error);
    }
  };

  const handleDeleteSound = async () => {
    try {
      await db.collection('sounds').doc(soundToDelete.id).delete();
      const soundsCollection = await db.collection('sounds').get();
      setSounds(soundsCollection.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      setDeleteDialogOpen(false);
      setOpenDialog(false);
      setSoundToDelete(null);
    } catch (error) {
      setError('Error deleting sound. Please try again.');
      console.error('Error deleting sound:', error);
    }
  };

  const handleOpenDeleteDialog = (sound) => {
    setSoundToDelete(sound);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSoundToDelete(null);
  };

  const handleAddNote = () => {
    setNewSound({
      ...newSound,
      sequence: [...newSound.sequence, { note: 'A', type: 'Quarter', unit: 'Hz', duration: 1 }]
    });
  };

  const handleRemoveNote = (index) => {
    const updatedSequence = [...newSound.sequence];
    updatedSequence.splice(index, 1);
    setNewSound({ ...newSound, sequence: updatedSequence });
  };

  const handleNoteChange = (index, key, value) => {
    const updatedSequence = [...newSound.sequence];
    updatedSequence[index][key] = value;
    setNewSound({ ...newSound, sequence: updatedSequence });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setNewSound({ ...newSound, tabValue: newValue });
  };

  const handleTestSound = (soundToTest) => {
    const isValidSound = soundToTest.sequence.every((noteObj) => {
      return noteObj.note && noteObj.type && (tabValue === 0 ? noteObj.duration : true);
    });

    if (!isValidSound) {
      alert('Please make sure all notes and note types are filled out.');
      return;
    }

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    const noteTypeToDuration = (type, tempo) => {
      const noteDurations = {
        Whole: 4,
        Half: 2,
        Quarter: 1,
        Eighth: 0.5,
        Sixteenth: 0.25,
      };
      const beatsPerSecond = 60 / tempo;
      return noteDurations[type] * beatsPerSecond;
    };

    const playNote = (frequency, duration, isRest = false) => {
      if (isRest || !isFinite(frequency) || !isFinite(duration)) {
        return new Promise((resolve) => setTimeout(resolve, duration * 1000));
      }

      return new Promise((resolve) => {
        const oscillator = audioContext.createOscillator();
        const gainNode = audioContext.createGain();

        oscillator.connect(gainNode);
        gainNode.connect(audioContext.destination);

        oscillator.type = 'sine';
        oscillator.frequency.setValueAtTime(frequency, audioContext.currentTime);

        gainNode.gain.setValueAtTime(0.5, audioContext.currentTime);
        oscillator.start();
        setTimeout(() => {
          oscillator.stop();
          resolve();
        }, duration * 1000);
      });
    };

    const playSoundSequence = async () => {
      for (const noteObj of soundToTest.sequence) {
        let frequency;
        let duration;
        let isRest = false;

        if (tabValue === 0) {
          frequency = noteObj.unit === 'kHz' ? noteObj.note * 1000 : parseFloat(noteObj.note);
          duration = parseFloat(noteObj.duration);
        } else {
          const noteFrequencies = {
            A: 440,
            'A#': 466.16,
            Bb: 466.16,
            B: 493.88,
            C: 261.63,
            'C#': 277.18,
            Db: 277.18,
            D: 293.66,
            'D#': 311.13,
            Eb: 311.13,
            E: 329.63,
            F: 349.23,
            'F#': 369.99,
            Gb: 369.99,
            G: 392,
            'G#': 415.30,
            Ab: 415.30,
            Rest: 0 // Rest note has no frequency
          };
          
          frequency = noteFrequencies[noteObj.note];
          isRest = noteObj.note === 'Rest';
          duration = noteTypeToDuration(noteObj.type, soundToTest.tempo);
        }

        if (isFinite(frequency) && isFinite(duration)) {
          await playNote(frequency, duration, isRest);
        }
      }
    };

    playSoundSequence();
  };

  // Open the Import Dialog
  const handleImportDialogOpen = async () => {
    await fetchDefaultSounds(); // Fetch default sounds when opening the dialog
    setImportDialogOpen(true);
  };

  const handleImportDialogClose = () => {
    setImportDialogOpen(false);
  };

  const handleImportSound = async (sound) => {
    
    await db.collection('sounds').add(sound);
    setSounds([...sounds, sound]); // Add the selected sound from the default list
    setImportDialogOpen(false); // Close the dialog after import
  };

  return (
    <div>
      <Toolbar style={{display:"flex" , backgroundColor: '#373737', borderBottom: '1px solid rgba(81, 81, 81, 1)' }}>
        <IconButton color="primary" onClick={() => handleDialogOpen()}>
          <AddIcon /> {/* Add new sound button */}
        </IconButton>
        <h2>Sounds</h2>

        <Box sx={{flexGrow:1}}/>
        
        <IconButton color="primary" onClick={handleImportDialogOpen}>
          <ImportExportIcon /> {/* Import default sound button */}
        </IconButton>

      </Toolbar>

      {error && <Alert severity="error">{error}</Alert>}

      {loading ? (
        <Loading/>
      ) : (
        <List>
          {sounds && sounds.map(sound => (
            <ListItem key={sound.id} button onClick={() => handleDialogOpen(sound)}>
              <ListItemText
                primary={sound.name}
                secondary={`Duration: ${calculateTotalDuration(sound.sequence)}s`}
              />
            </ListItem>
          ))}
        </List>
      )}

      {/* Add/Edit Sound Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{editingSound ? 'Edit Sound' : 'Add New Sound'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Sound Name"
            fullWidth
            value={newSound.name}
            onChange={(e) => setNewSound({ ...newSound, name: e.target.value })}
          />

          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab value={1} label="Notes" />
            <Tab value={0} label="Frequency/Duration" />
          </Tabs>

          {tabValue === 0 && newSound.sequence && newSound.sequence.map((noteObj, index) => (
            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <FormControl style={{ marginRight: '10px', width: '48%' }}>
                <InputLabel>Frequency Unit</InputLabel>
                <Select
                  value={noteObj.unit || 'Hz'}
                  onChange={(e) => handleNoteChange(index, 'unit', e.target.value)}
                >
                  <MenuItem value="Hz">Hz</MenuItem>
                  <MenuItem value="kHz">kHz</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Frequency"
                margin="dense"
                type="number"
                value={noteObj.note}
                onChange={(e) => handleNoteChange(index, 'note', e.target.value)}
                style={{ marginRight: '10px', width: '48%' }}
              />
              <TextField
                label="Duration (s)"
                margin="dense"
                type="number"
                value={noteObj.duration}
                onChange={(e) => handleNoteChange(index, 'duration', e.target.value)}
                style={{ width: '48%' }}
              />
            </div>
          ))}

          {tabValue === 1 && (
            <>
              <TextField
                label="Tempo (BPM)"
                type="number"
                margin="dense"
                value={newSound.tempo}
                onChange={(e) => setNewSound({ ...newSound, tempo: e.target.value })}
                style={{ width: '100%' }}
              />

{newSound.sequence && newSound.sequence.map((noteObj, index) => (
  <Box key={index} display="flex" alignItems="center" justifyContent="space-between" mb={2}>
    <FormControl size="small" style={{ marginRight: '10px', width: '30%' }}>
      <InputLabel>Note</InputLabel>
      <Select
        value={noteObj.note || 'A'}
        onChange={(e) => handleNoteChange(index, 'note', e.target.value)}
      >
        <MenuItem value="A">A</MenuItem>
        <MenuItem value="A#">A#</MenuItem>
        <MenuItem value="Bb">Bb</MenuItem>
        <MenuItem value="B">B</MenuItem>
        <MenuItem value="C">C</MenuItem>
        <MenuItem value="C#">C#</MenuItem>
        <MenuItem value="Db">Db</MenuItem>
        <MenuItem value="D">D</MenuItem>
        <MenuItem value="D#">D#</MenuItem>
        <MenuItem value="Eb">Eb</MenuItem>
        <MenuItem value="E">E</MenuItem>
        <MenuItem value="F">F</MenuItem>
        <MenuItem value="F#">F#</MenuItem>
        <MenuItem value="Gb">Gb</MenuItem>
        <MenuItem value="G">G</MenuItem>
        <MenuItem value="G#">G#</MenuItem>
        <MenuItem value="Ab">Ab</MenuItem>
        <MenuItem value="Rest">Rest</MenuItem>
      </Select>
    </FormControl>
    <FormControl size="small" style={{ width: '30%' }}>
      <InputLabel>Type</InputLabel>
      <Select
        value={noteObj.type || 'Quarter'}
        onChange={(e) => handleNoteChange(index, 'type', e.target.value)}
      >
        <MenuItem value="Whole">Whole</MenuItem>
        <MenuItem value="Half">Half</MenuItem>
        <MenuItem value="Quarter">Quarter</MenuItem>
        <MenuItem value="Eighth">Eighth</MenuItem>
        <MenuItem value="Sixteenth">Sixteenth</MenuItem>
      </Select>
    </FormControl>
    <IconButton onClick={() => handleRemoveNote(index)} color="secondary" size="small">
      <DeleteIcon />
    </IconButton>
  </Box>
))}

            </>
          )}

          <Button onClick={handleAddNote} color="secondary" style={{ marginTop: '10px' }}>
            Add Another Note
          </Button>
        </DialogContent>
        <DialogActions>
          {editingSound && (
            <Button onClick={() => handleOpenDeleteDialog(editingSound)} color="secondary">
              Delete Sound
            </Button>
          )}
          <Button onClick={() => handleTestSound(newSound)} color="primary">
            Test Sound
          </Button>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveSound} color="primary">
            {editingSound ? 'Save Changes' : 'Add Sound'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation dialog for deleting sound */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Sound</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the sound "{soundToDelete?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSound} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Import default sounds dialog */}
      <Dialog open={importDialogOpen} onClose={handleImportDialogClose}>
        <DialogTitle>Import Default Sound</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <List>
              {defaultSounds && defaultSounds.map(sound => (
                <ListItem key={sound.id} button onClick={() => handleImportSound(sound)}>
                  <ListItemText
                    primary={sound.name}
                    secondary={`Duration: ${calculateTotalDuration(sound.sequence)}s`}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImportDialogClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SoundsPage;
