import React, { useState } from 'react';
import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { Button, Alert, TextField, Box, Typography, Container } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { auth } from '../firebase';


const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [name, setName] = useState('');
  const [isSignup, setIsSignup] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [error, setError] = useState(null);


  
  const handleLogin = async (e) => {
    e.preventDefault();
    if (isSignup && password !== passwordConfirm) {
      setError("Passwords do not match!");
      return;
    }

    try {
      if (isSignup) {
        await createUserWithEmailAndPassword(auth, email, password);
        console.log("Account created successfully!");
      } else {
        await signInWithEmailAndPassword(auth, email, password);
        console.log("Logged in successfully!");
      }
    } catch (error) {
      setError("Authentication error: " + error.message);
      console.error('Authentication error:', error);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("Logged in user:", user);
    } catch (error) {
      setError("Google login error: " + error.message);
      console.error('Google login error:', error);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      alert("Please enter your email address to reset your password.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent!");
    } catch (error) {
      console.error("Failed to send password reset email:", error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h1">
          <strong>Hide</strong>'n'<strong>Beep</strong>
        </Typography>
        <Box component="form" onSubmit={isForgotPassword ? handleForgotPassword : handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => { setEmail(e.target.value); setError(null); }}
          />
          {!isForgotPassword && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => { setPassword(e.target.value); setError(null); }}
            />
          )}
          {isSignup && (
            <>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="passwordConfirm"
                label="Confirm Password"
                type="password"
                id="passwordConfirm"
                value={passwordConfirm}
                onChange={(e) => { setPasswordConfirm(e.target.value); setError(null); }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="name"
                label="Full Name"
                id="name"
                value={name}
                onChange={(e) => { setName(e.target.value); setError(null); }}
              />
            </>
          )}

          {error && <Alert severity="error">{error}</Alert>}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {isSignup ? 'Sign Up' : isForgotPassword ? 'Send Reset Email' : 'Sign In'}
          </Button>

          <Button
            fullWidth
            variant="outlined"
            startIcon={<GoogleIcon />}
            sx={{ mt: 3, mb: 2 }}
            onClick={handleGoogleLogin}
          >
            Sign in with Google
          </Button>

          {!isSignup && !isForgotPassword && (
            <Button
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => { setIsForgotPassword(true); setError(null); }}
            >
              Forgot Password?
            </Button>
          )}
          {!isForgotPassword && (
            <Button
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => { setIsSignup(!isSignup); setIsForgotPassword(false); setError(null); }}
            >
              {isSignup ? 'Already have an account? Sign in' : "Don't have an account? Sign up"}
            </Button>
          )}
          {isForgotPassword && (
            <Button
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => { setIsForgotPassword(false); setError(null); }}
            >
              Back to sign in.
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
