import React, { useState } from 'react';

import { AppBar, Box,IconButton,Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import MenuIcon from '@mui/icons-material/Menu'; // Hamburger menu icon

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


const Navbar = () => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleMenuClose = () => {
    setMobileMenuOpen(false);
  };
  const handleLogout = () => {
    auth.signOut();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography component={Link} to="/" variant="h6" style={{ flexGrow: 1,textDecoration:"none" }}>
        <strong>Hide</strong> 'n' <strong>Beep</strong>
        </Typography>
        <Box sx={{ display: { xs: 'none', md: 'block' } }} >
          <Button  component={Link} to="/devices">Devices</Button>
          <Button  component={Link} to="/sounds">Sounds</Button>
          <Button  component={Link} to="/settings">Settings</Button>
          <Button  onClick={handleLogout}>Logout</Button>
        </Box>

        <IconButton
          edge="end"
          color="primary"
          aria-label="menu"
          onClick={handleMenuOpen}
          className="mobile-menu-icon"
          sx={{ display: { xs: 'block', md: 'none' } }} // Show only on small screens
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      {mobileMenuOpen ?
        <Box>
        
          <List component="nav" aria-label="secondary mailbox folder">
            <ListItemButton onClick={handleMenuClose} component={Link} to="/devices"><ListItemText primary="Devices" /></ListItemButton>
            <ListItemButton onClick={handleMenuClose} component={Link} to="/sounds"><ListItemText primary="Sounds" /></ListItemButton>
            <ListItemButton onClick={handleMenuClose} component={Link} to="/Settings"><ListItemText primary="Settings" /></ListItemButton>
            <ListItemButton onClick={()=>{handleMenuClose();handleLogout()}}><ListItemText primary="Logout" /></ListItemButton>

          </List>
        </Box>
      :null}


    </AppBar>
  );
};

export default Navbar;
