import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Routes, Route,  Outlet } from 'react-router-dom';
import { db } from '../firebase';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import Loading from './Loading';
import Page404 from '../pages/Page404'; // Import the 404 page
import { getAuth } from "firebase/auth";


const TabNavigation = () => {
  const location = useLocation();
  const auth =  getAuth();
  const { id } = useParams();

  const [device, setDevice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const tabsViewer = [
    { label: 'Overview', path: `/device/${id}` },
    { label: 'Schedule', path: `/device/${id}/schedule` },
    { label: 'Log', path: `/device/${id}/log` },
  ];

  
  const tabsAdmin = [
    { label: 'Overview', path: `/device/${id}` },
    { label: 'Schedule', path: `/device/${id}/schedule` },
    { label: 'Log', path: `/device/${id}/log` },
    { label: 'Settings', path: `/device/${id}/settings` },
  ];

  

  useEffect(() => {
    const fetchDevice = async () => {
      setLoading(true);
      try {
        const deviceDoc = await db.collection('devices').doc(id).get();
        if (deviceDoc.exists) {
          setDevice({ id: deviceDoc.id, ...deviceDoc.data() });
        } else {
          setError('Device not found');
        }
      } catch (error) {
        setError('Failed to load device data');
      } finally {
        setLoading(false);
      }
    };

    fetchDevice();
  }, [id]);

  
  if (loading) return <Loading />;
  if (error) return <Page404 />;

  //console.log(device.users[auth.currentUser.uid].permission)
  const tabs = device.users && (device.users[auth.currentUser.uid]) &&(device.users[auth.currentUser.uid].permission) && (["admin","owner"].includes(device.users[auth.currentUser.uid].permission)) ? tabsAdmin : tabsViewer

  // Find the index of the current tab based on the current URL
  const currentTabIndex = tabs.findIndex((tab) => location.pathname === tab.path);

  if (currentTabIndex == -1) return <Page404 />;




  return (
    <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTabIndex !== -1 ? currentTabIndex : false}
            variant="scrollable"
            scrollButtons="true"
            >
                
            <Tab

                value={"back"}
                label={<ArrowBackIosIcon/>}
                component={Link}
                to={"/devices"}
                sx={{     minWidth: "0px;",textTransform: 'none' }}
            />


            {tabs.map((tab, index) => (
            <Tab
            value={index}
                label={tab.label}
                component={Link}
                to={tab.path}
                sx={{ textTransform: 'none' }}
            />
            ))}
        </Tabs>
        </Box>
        <Outlet context={device} /> {/* Pass the device data to child routes */}
    </>

  );
};

export default TabNavigation;
