import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { Box, Divider, Alert, DialogContent, MenuItem, Select, TextField, Button, FormControl, InputLabel, CircularProgress } from '@mui/material';
import Loading from '../../components/Loading';
import { useOutletContext } from 'react-router-dom';

const DeviceDetail = () => {
  const { id } = useParams();
  const [device, setDevice] = useState(useOutletContext());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Default sound state
  const [defaultSound, setDefaultSound] = useState('none');
  const [randomSoundSettings, setRandomSoundSettings] = useState({ minFreq: '', maxFreq: '', minDuration: '', maxDuration: '' });
  const [fixedSoundSettings, setFixedSoundSettings] = useState({ freq: '', duration: '' });
  const [selectedSound, setSelectedSound] = useState(''); // Assuming this is fetched from a sound list

  // Event schedule state
  const [eventSchedule, setEventSchedule] = useState('schedule'); // 'schedule', 'random', 'periodic'
  const [cronSettings, setCronSettings] = useState({ time: '', day: '', month: '', year: '' });
  const [randomEventSettings, setRandomEventSettings] = useState({ minHours: '', maxHours: '' });
  const [periodicSettings, setPeriodicSettings] = useState({ interval: '', unit: 'minutes' }); // unit can be 'minutes', 'hours', or 'days'


   // Populate state with data from the device object
   useEffect(() => {
    if (device) {
      setDefaultSound(device.defaultSound || 'none');
      setRandomSoundSettings(device.randomSoundSettings || { minFreq: '', maxFreq: '', minDuration: '', maxDuration: '' });
      setFixedSoundSettings(device.fixedSoundSettings || { freq: '', duration: '' });
      setSelectedSound(device.selectedSound || '');

      setEventSchedule(device.eventSchedule || 'schedule');
      setCronSettings(device.cronSettings || { time: '', day: '', month: '', year: '' });
      setRandomEventSettings(device.randomEventSettings || { minHours: '', maxHours: '' });
      setPeriodicSettings(device.periodicSettings || { interval: '', unit: 'minutes' });
    }
  }, [device]);

  
  // Save device function
  const handleSaveDevice = async () => {
    setLoading(true);
    setError(null);

    // Construct the updated device data
    const updatedDevice = {
      ...device,
      defaultSound,
      randomSoundSettings: defaultSound === 'random' ? randomSoundSettings : null,
      fixedSoundSettings: defaultSound === 'set' ? fixedSoundSettings : null,
      selectedSound: defaultSound === 'sound' ? selectedSound : null,
      eventSchedule,
      cronSettings: eventSchedule === 'schedule' ? cronSettings : null,
      randomEventSettings: eventSchedule === 'random' ? randomEventSettings : null,
      periodicSettings: eventSchedule === 'periodic' ? periodicSettings : null,
    };

    try {
      await db.collection('devices').doc(id).update(updatedDevice);
      setLoading(false);
    } catch (error) {
      setError('Error saving device');
      setLoading(false);
    }
  };

  if (loading) return <Loading />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ width: '100%' }}>

      <DialogContent>
        {/* Device Name and ID (ID cannot be changed) */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            label="Device Name"
            value={device.name}
            onChange={(e) => setDevice({ ...device, name: e.target.value })}
            fullWidth
          />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            label="Device ID"
            value={device.id}
            disabled
            fullWidth
          />
        </FormControl>


        {loading && <CircularProgress />}

        <Button variant="contained" color="primary" onClick={handleSaveDevice} disabled={loading}>
          {loading ? 'Saving...' : 'Save Device'}
        </Button>
      </DialogContent>
    </Box>
  );
};

export default DeviceDetail;
