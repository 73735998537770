import { createTheme } from '@mui/material/styles';

// Toggle primary color between Teal and Orange
const useTeal = 0; // Set to false to use Orange

const primaryColor =  '#ff9800'; // Teal or Orange

const theme = createTheme({
  palette: {
    mode: 'dark',  // Dark mode
    primary: {
      main: primaryColor,  // Primary color based on the toggle (Teal or Orange)
    },
    background: {
      default: '#1e1e1e',  // Dark gray background
      paper: '#2c2c2c',  // Slightly lighter gray for surfaces
    },
    text: {
      primary: '#ffffff',  // White text across the app
      secondary: '#e0e0e0',  // Light gray for subtle text if needed
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      color: primaryColor,  // Headings in primary color
      fontSize: '2.5rem',
    },
    h2: {
      color: primaryColor,  // Headings in primary color
      fontSize: '2rem',
    },
    h3: {
      color: primaryColor,  // Headings in primary color
      fontSize: '1.75rem',
    },
    h4: {
      color: primaryColor,  // Headings in primary color
      //fontSize: '1.75rem',
    },
    h5: {
      color: primaryColor,  // Headings in primary color
      //fontSize: '1.75rem',
    },
    h6: {
      color: primaryColor,  // Headings in primary color
      //fontSize: '1.75rem',
    },
    body1: {
      color: '#ffffff',  // Main body text in white
    },
    button: {
      textTransform: 'none',  // Keep button text lowercase
      color: '#ffffff',  // Button text color is white
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          //backgroundColor: primaryColor,  // Buttons in primary color
          //color: '#ffffff',  // Button text in white
          '&:hover': {
           // backgroundColor: useTeal ? '#00796b' : '#e65100',  // Darker teal or orange on hover
          },
        },
      },
    },
  },
});

export default theme;
