import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Alert, IconButton, Box } from '@mui/material';
import { getAuth, updateEmail, updatePassword } from "firebase/auth";
import { db } from '../firebase'; // Firestore instance for API keys
import { v4 as uuidv4 } from 'uuid'; // For generating new API keys
import DeleteIcon from '@mui/icons-material/Delete';

const SettingsPage = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const [apiKeys, setApiKeys] = useState([]);
  const [newApiKeyName, setNewApiKeyName] = useState('');
  const [newEmail, setNewEmail] = useState(currentUser?.email || '');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [googleProvider, setGoogleProvider] = useState(false); // Google account flag

  // Fetch API keys from Firestore
  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const apiKeysSnapshot = await db.collection('users')
          .doc(currentUser.uid)
          .collection('apiKeys')
          .get();

        const apiKeysList = apiKeysSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setApiKeys(apiKeysList);
      } catch (error) {
        setError('Failed to load API keys');
      }
    };

    if (currentUser) {
      fetchApiKeys();
    }
  }, [currentUser]);

  // Detect if the user is signed in with Google
  useEffect(() => {
    if (currentUser?.providerData.some((provider) => provider.providerId === 'google.com')) {
      setGoogleProvider(true);
    }
  }, [currentUser]);

  // Generate and save a new API key
  const handleGenerateApiKey = async () => {
    const newApiKey = uuidv4();

    if (!newApiKeyName) {
      setError('API key name is required');
      return;
    }

    try {
      const newKeyData = { key: newApiKey, name: newApiKeyName, createdAt: new Date() };
      const docRef = await db.collection('users')
        .doc(currentUser.uid)
        .collection('apiKeys')
        .add(newKeyData);

      setApiKeys([...apiKeys, { id: docRef.id, ...newKeyData }]);
      setNewApiKeyName('');
      setSuccess('API key generated successfully');
    } catch (error) {
      setError('Failed to generate API key');
    }
  };

  // Delete API key
  const handleDeleteApiKey = async (keyId) => {
    try {
      await db.collection('users')
        .doc(currentUser.uid)
        .collection('apiKeys')
        .doc(keyId)
        .delete();

      setApiKeys(apiKeys.filter(key => key.id !== keyId));
      setSuccess('API key deleted successfully');
    } catch (error) {
      setError('Failed to delete API key');
    }
  };

  // Update email
  const handleEmailChange = async () => {
    try {
      await updateEmail(currentUser, newEmail);
      setSuccess('Email updated successfully');
    } catch (error) {
      setError('Failed to update email: ' + error.message);
    }
  };

  // Update password
  const handlePasswordChange = async () => {
    try {
      await updatePassword(currentUser, newPassword);
      setSuccess('Password updated successfully');
    } catch (error) {
      setError('Failed to update password: ' + error.message);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>

      {/* Display API Keys */}
      <Typography variant="h6">API Keys</Typography>
      {apiKeys.length > 0 ? (
        apiKeys.map((keyObj, index) => (
          <Box key={index} display="flex" alignItems="center" mb={2}>
            <TextField
              label={`API Key ${index + 1}`}
              value={keyObj.key}
              fullWidth
              margin="normal"
              disabled
            />
            <TextField
              label="API Key Name"
              value={keyObj.name}
              fullWidth
              margin="normal"
              disabled
              sx={{ ml: 2 }}
            />
            <IconButton color="secondary" onClick={() => handleDeleteApiKey(keyObj.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))
      ) : (
        <Typography>No API keys available</Typography>
      )}

      {/* Add new API key */}
      <TextField
        label="New API Key Name"
        value={newApiKeyName}
        onChange={(e) => setNewApiKeyName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleGenerateApiKey}>
        Generate API Key
      </Button>

      {/* Display account settings */}
      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Account Settings
      </Typography>

      {/* Google Account Notice */}
      {googleProvider ? (
        <Alert severity="info">
          You are signed in with Google. Some settings like changing your password must be done via Google.
        </Alert>
      ) : (
        <>
          <TextField
            label="New Email"
            value={newEmail}
            fullWidth
            margin="normal"
            onChange={(e) => setNewEmail(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handleEmailChange}>
            Update Email
          </Button>

          <TextField
            label="New Password"
            type="password"
            fullWidth
            margin="normal"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handlePasswordChange}>
            Update Password
          </Button>
        </>
      )}

      {/* Error and success alerts */}
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
    </Container>
  );
};

export default SettingsPage;
